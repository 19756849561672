//---------------------------------------------
//
//  Table of Contents
//
//    1. Basics
//    2. Page Layout
//    3. Components
//
//---------------------------------------------

//---------------------------------------------
// 1. Basics
//---------------------------------------------

@import "variables";
@import "bootstrap";
@import "fonts";

//---------------------------------------------
// 2. Page Layout
//---------------------------------------------
/*$primary-font: "Montserrat";
$secondary-font: "Poppins";
$main-color: #025B00;
$highlight-text: #08D003;
$radius-30: 30px;
$radius-40: 40px;
*/

body {
    font-family: var(--primary-font);
    background: #E5E5E5;
}
.highlight-text{
    color: var(--highlight-text);
}
.hero-section{
   padding-top: 50px;
   padding-bottom: 70px;
}
.hero-btn{
    background: var(--main-color);
    font-family: var(--primary-font);
    font-weight: 600;
    font-size: 18px;
    line-height: 45px;
    margin-bottom: 20px;
    color:#fff !important;
    border: none;
    border-radius: var(--radius-30);
    width:238px;
    max-width: 100%;
    height: 56px;
    padding: 5px;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    background-image: url(../../dist/images/Section1/phone-icon.png);
    background-repeat: no-repeat;
    background-position: 8.32% center;
}
.hero-btn:hover{
    background-color: var(--highlight-text);
}
.hero-btn-light{
    background: #fff !important;
    color: var(--main-color) !important;
}
.hero-btn-light:hover{
    opacity: 0.8;
}
.cn-title{
    font-family: var(--primary-font);
    color: var(--main-color);
    font-weight: 800;
    font-size: 30px;
    line-height: 50px;
}
.side-box{
    background: var(--highlight-text);
    display: inline-block;
    line-height: 30px;
    padding: 20px 20px;
    color: #fff;
    width: 214px;
    height: 176px;
    border-radius: 15px;
    vertical-align: middle;
    font-size: 26px;
    font-weight: 600;
}
#hero-image{
    border-radius: var(--radius-40);
    position: relative;
}
#hero-image-holder{
    position: relative;
}
.hero-btn-lg{
    background: #276cc3;
    color: #fff;
    text-decoration: none;
    padding: 20px;
    border-radius: 50px;
    display: block;
    font-weight: 600;
}
.hero-btn-lg:hover{
    opacity: 0.8;
    color: #fff;
}
.bg-img-section-one{
    position: absolute;
    bottom: 0px;
    top: 0px;
    left: 0px;
    height: 100%;
    overflow: hidden;
    border-radius: 15px 15px 150px 15px;
    z-index: -1;
    max-width: 477px;
}
.bg-img-section-two{
    position: absolute;
    bottom: 0px;
    top: 0px;
    right: 0px;
    height: 100%;
    overflow: hidden;
    border-radius: 15px 15px 15px 150px;
    z-index: -1;
    max-width: 477px;
}
.content-holder-sections{
    font-family: var(--primary-font);
    color: var(--main-color);
    font-weight: 600;
    position: relative;
    padding: 38px 38px 50px 80px;
    margin: 20px 40px 40px auto;
    border-radius: 135px 15px;
    background: rgba(255, 255, 255, 0.72);
    max-width: 674px;
}
.content-holder-sections-left{
    font-family: var(--primary-font);
    color: var(--main-color);
    font-weight: 600;
    position: relative;
    padding: 38px 38px 50px 80px;
    margin: 20px 40px 40px 0px;
    border-radius: 135px 15px;
    background: rgba(255, 255, 255, 0.72);
    max-width: 674px;
}
.section-heading-light{
    font-family: var(--primary-font);
    color: var(--main-color);
    font-weight: 800;
    font-size: 60px;
    line-height: 50px;
}
.section-heading-dark{
    font-family: var(--primary-font);
    color: #fff;
    font-weight: 800;
    font-size: 60px;
    line-height: 50px;
}
#reviews-section{
    background: var(--main-color);
    border-radius: 50px;
}
#hero-h1{
    font-family: var(--primary-font);
    color: var(--main-color);
    font-weight: 600;
    font-size: 60px;
    line-height: 73px;
    position: relative;
    padding: 40px 80px;
    border-radius: 135px 15px;
    max-width: 674px;
    background: rgba(255, 255, 255, 0.72);
}
.hero-section-bg{
    position: absolute;
    bottom: 0px;
    right: 0px;
    overflow: hidden;
    border-radius: 10px 10px 10px 150px;
    z-index: -1;
    max-width: 613px;
}
#hero-content{
    font-family: var(--primary-font);
    font-size: 20px;
    line-height: 24px;
}
#highlights-section{
    position: relative;
    background: #fff;
    width:80%;
    max-width: 1320px;
    margin:0 auto;
    margin-top: -100px;
    margin-bottom: 30px;
    border-radius: var(--radius-40);
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.25);
    padding: 50px;
}
.highlight-section-content{
    font-family: var(--primary-font);
    color: #000;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
}
.highlight-link{
    color: var(--main-color);
    text-decoration: none;
}
.main-content-section-heading{
    font-family: var(--primary-font);
    font-style: normal;
    font-weight: 700;
    font-size: 70px;
    line-height: 85px;
    color: white;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}
.main-content-section-heading-dark{
    font-family: var(--primary-font);
    color: var(--main-color);
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 85px;
}
.main-content-section-subheading{
    font-family: var(--primary-font);
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 37px;
}
.regular-content{
    font-family: var(--secondary-font);
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    text-align: justify;
}
.frame-image{
    width: 100%;
}
.main-content-section-image-holder{
    position: relative;
}
.main-content-section-image-holder::before{
    content: '';
    background: url(../../dist/images/Section1/dot-pattern-top-of-the-photo.png);
    background-size: contain;
    background-repeat: no-repeat;
    width: 242.38px;
    height: 242.38px;
    position: absolute;
    left: -30px;
    top: -30px;
    overflow: hidden;
}
.main-content-section-image-holder::after{
    content: '';
    background: url(../../dist/images/Section1/dot-pattern-bottom-of-the-photo.png);
    background-size: contain;
    background-repeat: no-repeat;
    width: 168.68px;
    height: 242.38px;
    position: absolute;
    right: -30px;
    bottom: -30px;
    overflow: hidden;
    pointer-events: none;
}
.image-button{
    position: absolute;
    left: 5%;
    bottom: 5%;
    background: var(--main-color);
    border-radius: var(--radius-30);
    font-family: var(--primary-font);
    font-weight: 600;
    width: 159px;
    padding: 10px 0px 10px 15px;
    text-align: center;
    text-decoration: none;
    font-size: 18px;
    line-height: 22px;
    background-image: url(../../dist/images/Section1/phone-icon.png);
    background-repeat: no-repeat;
    background-position: 9% center;
    background-size: 25px;
}
.image-button:hover{
    background-color: var(--highlight-text);
}
.image-video{
    margin: 0 auto;
    max-width: 100%;
    border-radius: var(--radius-40);
}
/*Section Two*/
#main-content-section-two, #main-content-section-four{
    position: relative;
    background-color: #F1F1F1;
}
#main-content-section-one, #slider-section{
    position: relative;
}
#slider-section{
    background-color: var(--main-color);
    border-radius: 50px;
    overflow: hidden;
}
.list-style-dark-section{
    color: #fff;
    font-size: 22px;
    line-height: 40px;
    list-style: none;
}
.list-style-dark-section li{
    margin-top: 15px;
    margin-bottom: 15px;
}
/*#slider-section::before{
    content: '';
    background: url(../../dist/images/Section1/blue-dots.png);
    width: 187.97px;
    height: 68.8px;
    position: absolute;
    left: -58px;
    top: calc(50% - 34.4px);
    pointer-events: none;
    transform: rotate(90deg);
    filter: var(--image-color-shift);
}
#main-content-section-one::after{
    content: '';
    background: url(../../dist/images/Section1/blue-dots.png);
    width: 187.97px;
    height: 68.8px;
    position: absolute;
    top: calc(50% - 34.4px);
    pointer-events: none;
    transform: rotate(90deg);
    right: -58px;
    filter: var(--image-color-shift);
}*/
#slider-section::before{
    content: '';
    background: url(../../dist/images/arrow-right.svg);
    width: 234.97px;
    height: 68.8px;
    position: absolute;
    left: -53px;
    top: calc(50% - 34.4px);
    pointer-events: none;
    transform: rotate(0deg);
    filter: hue-rotate(0deg) contrast(0) brightness(0) invert(100%);
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
}
#slider-section::after{
    content: '';
    background: url(../../dist/images/arrow-left.svg);
    width: 234.97px;
    height: 68.8px;
    position: absolute;
    top: calc(50% - 34.4px);
    pointer-events: none;
    transform: rotate(0deg);
    right: -53px;
    filter: hue-rotate(0deg) contrast(0) brightness(0) invert(100%);
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
}
#main-content-section-two::before{
    content: '';
    background: url(../../dist/images/Section1/blue-dots.png);
    width: 187.97px;
    height: 68.8px;
    position: absolute;
    right: 0px;
    top: calc(50% - 34.4px);
    pointer-events: none;
}
#main-content-section-two::after{
    content: '';
    background: url(../../dist/images/Section1/blue-dots.png);
    width: 187.97px;
    height: 68.8px;
    position: absolute;
    right: 0px;
    top: calc(50% - 34.4px);
    pointer-events: none;
}
#main-content-section-four::before{
    content: '';
    background: url(../../dist/images/Section1/blue-dots.png);
    width: 187.97px;
    height: 68.8px;
    position: absolute;
    left: 0px;
    top: 55px;
    pointer-events: none;
}
#main-content-section-four::after{
    content: '';
    background: url(../../dist/images/Section1/blue-dots.png);
    width: 187.97px;
    height: 68.8px;
    position: absolute;
    right: 0px;
    top: 55px;
    pointer-events: none;
}
#main-content-section-four::after, #main-content-section-four::before{
    filter: var(--image-color-shift);
}
#main-content-section-two .main-content-section-heading, #main-content-section-four .main-content-section-heading{
    color: #F1F1F1;
}
.list-style li{
    font-family: var(--secondary-font);
    text-align: left;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
}
.image-button-2{
    position: absolute;
    left: 5%;
    top: 5%;
    background: var(--main-color);
    border-radius: var(--radius-30);
    font-family: var(--primary-font);
    font-weight: 600;
    width: 159px;
    padding: 10px 0px 10px 15px;
    text-align: center;
    text-decoration: none;
    font-size: 18px;
    line-height: 22px;
    background-image: url(../../dist/images/Section1/phone-icon.png);
    background-repeat: no-repeat;
    background-position: 9% center;
    background-size: 25px;
}
.image-button-2:hover{
    background-color: var(--highlight-text);
}
.review-author{
    font-family: var(--secondary-font);
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 37px;
}
.review-content{
    font-family: var(--secondary-font);
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
}
.review-star-rating img{
    width: 30px;
}
#final-cta-section{
    position: relative;
    background: var(--highlight-text);
    width:80%;
    max-width: 1320px;
    margin:0 auto;
    margin-bottom: 100px;
    border-radius: var(--radius-40);
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.25);
    padding: 50px;
}
#footer-section{
    background: var(--main-color);
}
.section-five-heading{
    font-family: var(--primary-font);
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 49px;
    margin-bottom: 30px;
}
.footer-menu{
    list-style: none;
}
.footer-menu li{
    display: inline-block;
    margin-right: 20px;
    font-family: var(--primary-font);
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
}
.footer-menu li a{
    text-decoration: none;
    color: #000;
}
.footer-menu li a:hover{
    color: var(--main-color);
}
.carousel-control-next-icon, .carousel-control-prev-icon{
    background-color: var(--main-color);
}
@media only screen and (max-width: 1430px) {
    #main-content-section-one::before, #main-content-section-one::after, #main-content-section-four::before, #main-content-section-four::after, #slider-section::before, #slider-section::after, #final-cta-section::before, #final-cta-section::after{
        display: none;
    }
  }
  @media only screen and (max-width: 992px) {
    .cn-holder{
        align-items: center;
        justify-content: center;
        display: flex;
      }
      .cn-title{
          margin-bottom: 0px !important;
      }
    #main-content-section-two::before, #main-content-section-two::after, #hero-image-holder::before{
        display: none;
    }
    .side-box{
        margin-top: 20px;
        margin-right: 2px;
        margin-left: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .hero-section{
        padding-bottom: 20px;
    }
    #main-content-section-one, #services-section{
        margin-top: 0px !important;
        padding-top: 20px !important;
    }
    #atf-cta-btn-container{
        margin-bottom: 0px !important;
    }
    .section-heading-light{
        line-height: 22px;
        text-align: center !important;
    }
    .content-holder-sections{
        margin: 20px 0px 40px auto;
    }
    .section-heading-light, .section-heading-dark{
        font-size: 50px;
    }
  }
  @media only screen and (max-width: 767px) {
      
    #hero-image-holder::after{
        display: none;
    }
    .list-style li{
        text-align: center;
        list-style-type: none;
        
    }
    .hero-btn{
        margin: 10px auto;
    }
    #hero-image-holder {
        align-items: center;
        justify-content: center;
        width: 100% !important;
        margin: 0px !important;
    }
    .side-box {
        margin-top: 10px !important;
        margin-bottom: 0px !important;
    }
    .hero-section-bg{
        border-radius: 0px;
        width: 100%;
    }
    .cn-title{
        text-align: center;
    }
    .hero-section{
        padding-top: 0px;
    }
    .bg-img-section-one, .bg-img-section-two{
        width: 100%;
        max-width: none;
        border-radius: 15px !important;
    }
    .content-holder-sections-left, .content-holder-sections{
        margin: 20px;
    }
    .arrows-imgs{
        display: none;
    }
  }
  @media only screen and (min-width: 767px) and (max-width: 991px) {
    .list-style li{
        text-align: center;
        list-style-type: none;
        
    }
    .hero-btn{
        margin: 10px auto;
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1200px) {
    #highlights-section{
        width: 98%;
    }
    #hero-h1{
        font-size: 45px;
        line-height: 50px;
    }
  }
  @media only screen and (max-width: 991px) {
    #hero-image-holder{
        text-align: center;
    }
    .hero-banner .hero-btn{
        margin-bottom: 50px !important;
    }
    #highlights-section{
        padding: 20px;
    }
    .main-content-section-image-holder::before, .main-content-section-image-holder::after{
        display: none;
    }
    .main-content-section-heading{
        font-size: 32px;
        line-height: 32px;
        text-align: center;
    }
    .main-content-section-subheading{
        font-size: 25px;
        text-align: center;
    }
    .section-five-heading{
        font-size: 22px;
        line-height: 26px;
    }
    .hero-btn{
        width: 100%;
        max-width: 300px;
        background-image: none;
    }
    #hero-h1 {
        font-family: var(--primary-font);
        font-weight: 600;
        font-size: 35px;
        line-height: 50px;
        position: relative;
        text-align: center;
    }
    #hero-content, .hero-banner .col-lg-6, #main-content-section-four div{
        text-align: center;
    }
    #hero-h1::before{
        left: 0;
        right: 0;
        top: -100px;
        margin: 0 auto;
    }
    #highlights-section{
        margin-bottom: 30px;
    }
    footer div{
        text-align: center;
    }
    #final-cta-section{
        padding: 10px;

    }
  }
  @media only screen and (max-width: 460px) {
    .side-box{
        display: block;
        width: 100%;
        vertical-align: middle;
        height: auto;
    }
    .hero-btn-lg{
        background: #112640;
    }
    #hero-h1{
        padding: 10px;
        border-radius: 10px;
    }
    .content-holder-sections-left, .content-holder-sections {
        margin: 20px 10px;
        padding: 10px;
        border-radius: 5px;
        text-align: center;
        font-weight: normal;
    }
    .section-heading-light, .section-heading-dark {
        font-size: 32px;
        line-height: 36px;
    }
    .list-style-dark-section{
        padding: 0px !important;
        margin: 0px !important;
    }
    .list-style-dark-section li{
        font-size: 16px;
    }
    .review-author, .review-content, .review-star-rating{
        text-align: center;
    }
    .section-five-heading {
        font-size: 22px;
        line-height: 35px;
        margin-bottom: 10px;
    }
    #final-cta-section{
        margin-bottom: 60px;
    }
    .bg-img-section-one, .bg-img-section-two{
        filter: blur(3px);
    }
    #list-container{
        margin-bottom: 0px !important;
    }
    .list-style-dark-section li {
        font-size: 16px;
        line-height: 24px;
        border-bottom: 1px solid #fff;
        padding: 10px !important;
        margin: 0px;
    }
    .list-style-dark-section li:last-child{
        border: none;
    }
    .mobile-zero-margin{
        margin: 0px !important;
    }
    #slider-section{
        padding: 20px 10px !important;
    }
  }
//---------------------------------------------
// 3.Components
//---------------------------------------------

@import "components/slider";